<template>
    <div class="positionT0L0">
        <div class="box">
            <a-alert message="点击刷新后，以下用户数据将展示当日实时数据" type="warning" show-icon style="margin-bottom: 20px"/>
            <div class="topBox">
                <a-button v-if="isUserAgentChange" class="margin_right20" type="primary" @click="changeChannel">变更渠道归属</a-button>
                <a-button v-if="isRefreshOne" type="primary" @click="refreshClick(1)">刷新</a-button>
            </div>
            <div colspan="conBox">
                <div class="item">
                    <table border="1" style="border: 1px solid #D7D7D8">
                        <div class="borderItem">
                            <tr>
                                <th>头像</th>
                                <th>
                                    <img style="width: 100px;height: 100px;object-fit: contain" :src="baseData.imageUrl" alt="">
                                </th>
                            </tr>
                            <tr>
                                <th>用户学号</th>
                                <th>{{ baseData.userNo }}</th>
                            </tr>
                            <tr>
                                <th>姓名</th>
                                <th>{{ baseData.nickName }}</th>
                            </tr>
                            <tr>
                                <th>性别</th>
                                <th>{{ baseData.sex == 1 ? '男' : baseData.sex == 2 ? '女' : baseData.sex == 3 || baseData.sex == 0 ? '保密' : '--' }}</th>
                            </tr>
                            <tr>
                                <th>生日</th>
                                <th>{{ baseData.birthday }}</th>
                            </tr>
                            <tr >
                                <th>学习阶段</th>
                                <th>{{ baseData.grade == 0 ? '学前' : baseData.grade == 1 ? '一年级' : baseData.grade == 2 ? '二年级' : baseData.grade == 3 ? '三年级' : baseData.grade == 4 ? '四年级' : baseData.grade == 5 ? '五年级' : baseData.grade == 6 ? '六年级' : baseData.grade == 7 ? '初一' : baseData.grade == 8 ? '初二' : baseData.grade == 9 ? '初三' : baseData.grade == 10 ? '高一' : baseData.grade == 11 ? '高二' : baseData.grade == 12 ? '高三' : baseData.grade == 13 ? '自由学习者' : '--'}}</th>
                            </tr>
                            <tr>
                                <th>当前积分</th>
                                <th>{{ baseData.totalCredit }}</th>
                            </tr>
                            <tr>
                                <th>所在城市</th>
                                <th>{{ !baseData.phoneBelong ? '--' : baseData.phoneBelong }}</th>
                            </tr>
                        </div>
                    </table>
                </div>
                <div class="item">
                    <table border="1" style="border: 1px solid #D7D7D8">
                        <div class="borderItem">
                            <tr>
                                <th>手机号</th>
                                <th>{{ extData.userName }}</th>
                            </tr>
                            <tr>
                                <th>注册方式</th>
                                <th v-if="extData.source == 1">APP_Android</th>
                                <th v-else-if="extData.source == 2">APP_iOS</th>
                                <th v-else-if="extData.source == 3">直播_视频号</th>
                                <th v-else-if="extData.source == 4">直播_抖音</th>
                                <th v-else-if="extData.source == 5">直播预约小程序</th>
                                <th v-else-if="extData.source == 6">H5阅读大使推广二维码</th>
                                <th v-else-if="extData.source == 7">H5大使推广二维码</th>
                                <th v-else-if="extData.source == 8">人工操作</th>
                                <th v-else-if="extData.source == 9">历史注册</th>
                                <th v-else-if="extData.source == 10">H5官方网站</th>
                                <th v-else-if="extData.source == 99">其他</th>
                                <th v-else>--</th>
                            </tr>
                            <tr>
                                <th>注册归属</th>
                                <th>{{ extData.registerAgentName }}</th>
                            </tr>
                            <tr>
                                <th>当前渠道归属</th>
                                <th>{{ extData.newestAgentName }}</th>
                            </tr>
                            <tr>
                                <th>是否为阅读大使</th>
                                <th>{{ extData.isAgent == 1 ? '是' : '否' }}</th>
                            </tr>
                            <tr>
                                <th>注册时间</th>
                                <th>{{ extData.registerTime }}</th>
                            </tr>
                            <tr>
                                <th>会员状态</th>
                                <th>{{ extData.vipStatus == 1 ? '仅注册' : extData.vipStatus == 2 ? '会期内' : extData.vipStatus == 3 ? '会期过期' : '--' }}</th>
                            </tr>
                            <tr>
                                <th>购买商品</th>
                                <th>{{ !extData.buyCourse ? '--' : extData.buyCourse }}</th>
                            </tr>
                            <tr>
                                <th>会员VIP日期</th>
                                <th>{{ extData.vipStart == '0001-01-01 00:00:00' ? '' : extData.vipStart }} -- {{ extData.vipEnd == '0001-01-01 00:00:00' ? '' : extData.vipEnd }}</th>
                            </tr>
                        </div>
                    </table>
                </div >
                <div class="item">
                    <table border="1" style="border: 1px solid #D7D7D8">
                        <div class="borderItem">
                            <tr>
                                <th>账号状态</th>
                                <th>{{ statusLog && statusLog.enableFlag == 1 ? '正常' : statusLog && statusLog.enableFlag == 2 ? '锁定' : '--' }}</th>
                            </tr>
                            <tr>
                                <th>账号状态备注</th>
                                <th>{{ !statusLog.remark ? '--' : statusLog.remark }}</th>
                            </tr>
                            <tr>
                                <th>最近一次打开APP</th>
                                <th>{{ !extData.lastOpenApp ? '--' : extData.lastOpenApp }}</th>
                            </tr>
                        </div>
                    </table>
                </div>
            </div>
        </div>

        <a-modal v-model="visible" title="更换渠道归属" @ok="handleOk" @cancel="handleCancel">
            <a-form-model v-if="visible" ref="ruleForm" :model="locking" :rules="rules" >
                <a-form-model-item ref="name" label="用户名称" prop="name">
                    <a-input v-model="locking.name" :disabled="true" placeholder="请输入用户名称" />
                </a-form-model-item>
                <a-form-model-item ref="phone" label="用户手机号" prop="phone">
                    <a-input v-model="locking.phone" :disabled="true" placeholder="请输入用户手机号" />
                </a-form-model-item>
                <a-form-model-item ref="isLocking" label="渠道归属" prop="isLocking">
                    <a-input v-model="locking.isLocking" :disabled="true" placeholder="请输入渠道归属" />
                </a-form-model-item>
                <a-form-model-item ref="channel" label="新渠道归属" prop="channel">
                    <Treeselect @treeDataChange="treeDataChange" :isDateShow="isDateShow"></Treeselect>
                    <span v-if="isChannelShow" style="color:#FF4D4F;">请选择新渠道归属</span>
                </a-form-model-item>
            </a-form-model>
        </a-modal>

        <footer-tool-bar :collapsed="sideCollapsed">
            <a-button @click="$router.go(-1)">返回</a-button>
        </footer-tool-bar>
    </div>
</template>

<script>
import Treeselect from "@/components/channel/treeselectCode";
import { baseMixin } from "@/store/app-mixin";
import FooterToolBar from "@/components/FooterToolbar";
import MyPagination from "@/components/pagination/MyPagination";
import {UserDetail,RefreshOne,UserAgentChange} from "@/request/api/userManage";
import {codeFn} from "@/utils/tools";
export default {
    mixins: [baseMixin],
    components: {Treeselect,MyPagination,FooterToolBar},
    created() {
        if (codeFn("/adminv2/usercenter/refreshOne")) this.isRefreshOne = true;
        if (codeFn("/adminv2/usercenter/userAgentChange")) this.isUserAgentChange = true;
        this.init()
    },
    data() {
        return {
            isRefreshOne:false,
            isUserAgentChange:false,

            baseData:{},
            extData:{},
            statusLog:{},
            visible:false,
            isChannelShow:false,
            locking:{
                name:'',
                phone:'',
                isLocking:'',
                channel:[],
            },
            rules:{
                channel: [{ required: true, message: "",}],
            },
            isDateShow:true,
        };
    },
    methods: {
        // 点击刷新数据
        refreshClick(val){
            RefreshOne({
                userNo:this.$route.params.id
            }).then((res) => {
                if (res.code === 200) {
                    this.init(val)
                }
            });
        },

        // 渠道归属
        treeDataChange(data){
            this.locking.channel = data
            this.isDateShow = true
        },

        // 获取详情数据
        init(val){
            UserDetail({
                userNo:this.$route.params.id
            }).then((res) => {
                if (res.code === 200) {
                    this.baseData = res.data.baseData
                    this.extData = res.data.extData
                    this.statusLog = res.data.statusLog
                    if(val == 1){
                        this.$message.success('刷新成功！')
                    }else if(val == 2){
                        this.$message.success('已更换渠道归属！')
                    }
                }
            });
        },

        // 变更渠道归属确定按钮
        handleOk(){
            let self = this
            if(this.locking.channel.length>0){
                this.isChannelShow = false
            }else{
                this.isChannelShow = true
                return false
            }
            self.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    UserAgentChange({
                        phone: this.extData.userName,
                        code: this.locking.channel.toString(),
                    }).then((res) => {
                        if (res.code === 200) {
                            this.visible = false
                            this.refreshClick(2)
                        }
                    });
                }
            });
        },

        // 点击取消弹框按钮
        handleCancel(){
            this.isChannelShow = false
        },

        // 点击变更所属渠道按钮
        changeChannel(){
            this.visible = true
            this.locking.name = this.baseData.nickName
            this.locking.phone = this.extData.userName
            this.locking.isLocking = this.extData.newestAgentName
        },
    },
};
</script>

<style lang="less" scoped>
/deep/.ant-form-item-control{
    line-height: 2 !important;
}
.box{
    width: 100%;
    display: flex;
    flex-direction: column;
    tr {
        th{
            width: 900px;
            padding: 5px 50px 5px 10px;
        }
        th:first-child {
            width: 180px;
            padding: 5px 10px 5px 50px;
            background-color: #EDF3F4;
            color: #207DA9;
            text-align: right;
            border: 1px solid rgba(0, 0, 0, 0.15);
        }
    }
    .item{
        margin-top: 20px;
    }
}
</style>
